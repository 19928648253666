<template>
  <v-container fill-height>
    <v-row>
      <v-form class="layout wrap align-content-start justify-center" ref="form">
    <v-card width="1000">
      <v-card-title class="headline white--text primary"> DATOS DE FACTURACIÓN</v-card-title>
      <v-container fluid grid-list-md>
        <v-form xs12 px-3 class="mainColumn" ref="form1">
          <span v-if="!makeInvoiceSuccess">
            <v-layout row wrap>
              <v-flex xs12>
                <FormField
                    :label="$translations.get('invoice_view.invoice_form.name','Nombre y apellidos')"
                    v-model="name"
                    maxlength=100
                    :rules="[
                      $validations.minChars(1)
                    ]"
                    clearable
                />
              </v-flex>
            </v-layout>
          <v-layout row wrap>
            <v-flex xs12>
              <FormField
                  :label="$translations.get('invoice_view.invoice_form.phone','Teléfono')"
                  maxlength=100
                  v-model="phone"
                  :rules="[
                      $validations.isPhone(),
                    ]"
                  counter
                  clearable
              />
            </v-flex>
          </v-layout>
            <v-layout row wrap>
              <v-flex xs12>
                <FormField
                    :label="$translations.get('invoice_view.invoice_form.enterprise','Empresa')"
                    maxlength=100
                    v-model="enterprise"
                    :rules="[
                      $validations.required(),
                      $validations.minChars(4)
                    ]"
                    counter
                    clearable
                    required
                />
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex xs12>
                <FormField
                    :label="$translations.get('invoice_view.invoice_form.vat_id','CIF')"
                    maxlength=100
                    v-model="vat_id"
                    :rules="[
                      $validations.required(),
                      $validations.isNIFOrCIF()
                    ]"
                    counter
                    clearable
                    required
                />
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex xs12>
                <FormField
                    :label="$translations.get('invoice_view.invoice_form.invoice_address','Dirección')"
                    maxlength=100
                    v-model="address"
                    :rules="[
                      $validations.required(),
                      $validations.minChars(4)
                    ]"
                    counter
                    clearable
                    required
                />
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex xs12>
                <FormField
                    :label="$translations.get('invoice_view.invoice_form.invoice_address_postal_code','Código postal')"
                    maxlength=100
                    v-model="postal_code"
                    :rules="[
                      $validations.required(),
                      $validations.minChars(5),
                      $validations.maxChars(5)
                    ]"
                    counter
                    clearable
                    required
                />
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex xs12>
                <FormField
                    :label="$translations.get('invoice_view.invoice_form.invoice_address_city','Ciudad')"
                    maxlength=100
                    v-model="city"
                    :rules="[
                      $validations.required(),
                      $validations.minChars(2)
                    ]"
                    counter
                    clearable
                    required
                />
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex xs12>
                <FormField
                    :label="$translations.get('invoice_view.invoice_form.invoice_address_state','Provincia')"
                    maxlength=100
                    v-model="state"
                    :rules="[
                      $validations.required(),
                      $validations.minChars(2)
                    ]"
                    counter
                    clearable
                    required
                />
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex xs12>
                <FormField
                    :label="$translations.get('invoice_view.invoice_form.email','Email')"
                    maxlength=100
                    v-model="email"
                    type="email"
                    :rules="[
                      $validations.required(),
                      $validations.isEmail()
                    ]"
                    counter
                    clearable
                    required
                />
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex xs1 class="text-xs-right ticket-radio-button">
                <v-checkbox value="privacyPolicies" v-model="radioButtons"/>
              </v-flex>
              <v-flex xs10 mt-3 class="text-xs-left ticket-radio-button-text">
              <v-list-item-title class="radio_buttons_text">
                  {{$translations.get('common.read_accept','He leído y acepto ')}}  la &nbsp;
                  <a href="https://ticksy.app/politica-de-privacidad/">
                    {{$translations.get('common.privacy_policies',' política de privacidad')}}
                  </a>
                </v-list-item-title>
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex xs12 mt-3 class="text-xs-left ticket-footer">
                <BaseSuperButton @click="makeInvoiceClick" :loading="loadingMakeInvoice" :disabled="!radioButtons"> FACTURAR </BaseSuperButton>
              </v-flex>
            </v-layout>
            </span>
          <span v-else>
            <v-layout row wrap>
              <v-list-item-title> FACTURA REALIZADA CON EXITO </v-list-item-title>
              <v-flex xs12 mt-3 class="text-xs-left ticket-footer">
                <v-flex xs12 mt-3 class="text-xs-right ticket-footer">
                  <BaseSuperButton color="info" @click="downloadPDFClick" :loading="loadingDownloadPDF"> DESCARGAR PDF <v-icon>ts-down</v-icon> </BaseSuperButton>
                </v-flex>
              </v-flex>
            </v-layout>
          </span>
        </v-form>
      </v-container>
    </v-card>
  </v-form>
    </v-row>
  </v-container>
</template>

<script>
import BaseSuperButton from "@/components/Base/BaseSuperButton.vue";
import FormField from "@/components/ui/FormField.vue";
import {validationMixin} from 'vuelidate'
import axios from "axios";
import {animateScrollIntoError} from '@/scripts/Utils';

export default {
  name: 'MakeInvoiceView',
  components: {FormField, BaseSuperButton},
  mixins: [validationMixin],
  props: {
    order_id: String
  },
  data: () => ({
    name: '',
    email: '',
    phone:null,
    enterprise:'',
    vat_id:'',
    address:'',
    postal_code:'',
    city:'',
    state:'',
    decode_path:'',
    loadingMakeInvoice:false,
    makeInvoiceSuccess:false,
    loadingDownloadPDF:false,
    radioButtons:null
  }),
  mounted() {
    const decode_path = atob(this.$route.params.token);
    const regexp = /^([^/]+)\/([^/]+)\/([^/]+)\/([^/]+)\/([^/]+)\/([^/]+)$/;
    if (regexp.test(decode_path)) {
      this.decode_path = decode_path;
    }
  },
  methods:{
    async makeInvoiceClick() {
      if (!this.validateTabs()) {
        return;
      }

      const [group_id, local_type_id, local_id, order_id, order_time,order_ticket_num] = this.decode_path.split("/");
      this.loadingMakeInvoice = true
      await this.$http.post(`/v2/invoice/make_ticket_invoice/${group_id}/${local_type_id}/${local_id}/${order_id}/${order_time}`, {
        data_invoice: {
          name: (this.name === '' ? this.enterprise : this.name),
          email: this.email,
          phone: this.phone,
          enterprise: this.enterprise,
          vat_id: this.vat_id,
          address: this.address,
          postal_code: this.postal_code,
          city: this.city,
          state: this.state,
          decode_path: this.decode_path
        }
      })
      this.loadingMakeInvoice = false
      this.makeInvoiceSuccess = true
    },
    async downloadPDFClick() {
      this.loadingDownloadPDF = true
      const [group_id, local_type_id, local_id, order_id, order_time,order_ticket_num] = this.decode_path.split("/");
      const pdfBuffer = (await axios({
        url: `/v2/orders/get_pdf_buffer/${group_id}/${local_type_id}/${local_id}/${order_id}/${order_time}`,
        method: 'GET',
        responseType: 'blob'
      })).data
      const blob = new Blob([pdfBuffer], {type: 'application/pdf'});
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute('download', order_ticket_num+'.pdf');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.loadingDownloadPDF = false
    },
    validateTabs() {
      for (let i = 1; i < 2; i++) {
        if (!this.validateTab(i)) {
          animateScrollIntoError(this.$refs['form' + i]);
          return false;
        }
      }
      return true;
    },
    validateTab(num) {
      return this.$refs['form' + num].validate();
    },
  }
}
</script>
<style>
.radio_buttons_text{
  display: contents;
}
</style>
