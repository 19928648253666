<template>
	<v-btn
		:color="color"
		class="superButton"
		:block="$vuetify.breakpoint.xs || block"
		large
		depressed
		:loading="loading"
		@click.native="onClick"
		:disabled="disabled"
		:to="to"
		:href="href"
		:target="target"
  >
		<slot></slot>
	</v-btn>
</template>



<script>
	export default {
		name: 'BaseSuperButton',
		props:{
			block:{
				type: Boolean,
				default: false
			},
			center:{
				type: Boolean,
				default: false
			},
			disabled:{
				type: Boolean,
				default: false
			},
			href:{
				type: String,
			},
			loading:{
				type: Boolean,
				default: false
			},
			target:{
				type: String,
				default: '_self'
			},
      color:{
				type: String,
				default: 'accent'
			},
			to:{
				type: [String, Object],
			},
		},
		data(){
			return {
			}
		},
		methods:{
			onClick(){
				this.$emit('click');
			},
		}
	}
</script>


<style lang="scss">
	.superButton {
    width: 100%;
		border-radius: 6px;
		height: 70px !important;
		font-size: 16px !important;
	}
</style>
