var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fill-height":""}},[_c('v-row',[_c('v-form',{ref:"form",staticClass:"layout wrap align-content-start justify-center"},[_c('v-card',{attrs:{"width":"1000"}},[_c('v-card-title',{staticClass:"headline white--text primary"},[_vm._v(" DATOS DE FACTURACIÓN")]),_c('v-container',{attrs:{"fluid":"","grid-list-md":""}},[_c('v-form',{ref:"form1",staticClass:"mainColumn",attrs:{"xs12":"","px-3":""}},[(!_vm.makeInvoiceSuccess)?_c('span',[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('FormField',{attrs:{"label":_vm.$translations.get('invoice_view.invoice_form.name','Nombre y apellidos'),"maxlength":"100","rules":[
                    _vm.$validations.minChars(1)
                  ],"clearable":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1)],1),_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('FormField',{attrs:{"label":_vm.$translations.get('invoice_view.invoice_form.phone','Teléfono'),"maxlength":"100","rules":[
                    _vm.$validations.isPhone() ],"counter":"","clearable":""},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})],1)],1),_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('FormField',{attrs:{"label":_vm.$translations.get('invoice_view.invoice_form.enterprise','Empresa'),"maxlength":"100","rules":[
                    _vm.$validations.required(),
                    _vm.$validations.minChars(4)
                  ],"counter":"","clearable":"","required":""},model:{value:(_vm.enterprise),callback:function ($$v) {_vm.enterprise=$$v},expression:"enterprise"}})],1)],1),_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('FormField',{attrs:{"label":_vm.$translations.get('invoice_view.invoice_form.vat_id','CIF'),"maxlength":"100","rules":[
                    _vm.$validations.required(),
                    _vm.$validations.isNIFOrCIF()
                  ],"counter":"","clearable":"","required":""},model:{value:(_vm.vat_id),callback:function ($$v) {_vm.vat_id=$$v},expression:"vat_id"}})],1)],1),_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('FormField',{attrs:{"label":_vm.$translations.get('invoice_view.invoice_form.invoice_address','Dirección'),"maxlength":"100","rules":[
                    _vm.$validations.required(),
                    _vm.$validations.minChars(4)
                  ],"counter":"","clearable":"","required":""},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}})],1)],1),_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('FormField',{attrs:{"label":_vm.$translations.get('invoice_view.invoice_form.invoice_address_postal_code','Código postal'),"maxlength":"100","rules":[
                    _vm.$validations.required(),
                    _vm.$validations.minChars(5),
                    _vm.$validations.maxChars(5)
                  ],"counter":"","clearable":"","required":""},model:{value:(_vm.postal_code),callback:function ($$v) {_vm.postal_code=$$v},expression:"postal_code"}})],1)],1),_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('FormField',{attrs:{"label":_vm.$translations.get('invoice_view.invoice_form.invoice_address_city','Ciudad'),"maxlength":"100","rules":[
                    _vm.$validations.required(),
                    _vm.$validations.minChars(2)
                  ],"counter":"","clearable":"","required":""},model:{value:(_vm.city),callback:function ($$v) {_vm.city=$$v},expression:"city"}})],1)],1),_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('FormField',{attrs:{"label":_vm.$translations.get('invoice_view.invoice_form.invoice_address_state','Provincia'),"maxlength":"100","rules":[
                    _vm.$validations.required(),
                    _vm.$validations.minChars(2)
                  ],"counter":"","clearable":"","required":""},model:{value:(_vm.state),callback:function ($$v) {_vm.state=$$v},expression:"state"}})],1)],1),_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('FormField',{attrs:{"label":_vm.$translations.get('invoice_view.invoice_form.email','Email'),"maxlength":"100","type":"email","rules":[
                    _vm.$validations.required(),
                    _vm.$validations.isEmail()
                  ],"counter":"","clearable":"","required":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1)],1),_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{staticClass:"text-xs-right ticket-radio-button",attrs:{"xs1":""}},[_c('v-checkbox',{attrs:{"value":"privacyPolicies"},model:{value:(_vm.radioButtons),callback:function ($$v) {_vm.radioButtons=$$v},expression:"radioButtons"}})],1),_c('v-flex',{staticClass:"text-xs-left ticket-radio-button-text",attrs:{"xs10":"","mt-3":""}},[_c('v-list-item-title',{staticClass:"radio_buttons_text"},[_vm._v(" "+_vm._s(_vm.$translations.get('common.read_accept','He leído y acepto '))+" la "),_c('a',{attrs:{"href":"https://ticksy.app/politica-de-privacidad/"}},[_vm._v(" "+_vm._s(_vm.$translations.get('common.privacy_policies',' política de privacidad'))+" ")])])],1)],1),_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{staticClass:"text-xs-left ticket-footer",attrs:{"xs12":"","mt-3":""}},[_c('BaseSuperButton',{attrs:{"loading":_vm.loadingMakeInvoice,"disabled":!_vm.radioButtons},on:{"click":_vm.makeInvoiceClick}},[_vm._v(" FACTURAR ")])],1)],1)],1):_c('span',[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-list-item-title',[_vm._v(" FACTURA REALIZADA CON EXITO ")]),_c('v-flex',{staticClass:"text-xs-left ticket-footer",attrs:{"xs12":"","mt-3":""}},[_c('v-flex',{staticClass:"text-xs-right ticket-footer",attrs:{"xs12":"","mt-3":""}},[_c('BaseSuperButton',{attrs:{"color":"info","loading":_vm.loadingDownloadPDF},on:{"click":_vm.downloadPDFClick}},[_vm._v(" DESCARGAR PDF "),_c('v-icon',[_vm._v("ts-down")])],1)],1)],1)],1)],1)])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }