<template>
  <v-text-field
    :autofocus="autofocus"
    :append-icon="appendIcon"
    :append-outer-icon="appendOuterIcon"
    :class="['formField', {'translatable': translatable}]"
    :clearable="internalClearable"
    :counter="internalCounter"
    :disabled="disabled"
    :maxlength="maxlength"
    :hint="hint"
    :persistent-hint="persistentHint"
    :name="name"
    :placeholder="placeholder"
    :readonly="readonly"
    :required="required"
    :rules="rules"
    :type="internalType"
    v-model="computedValue"
    @click:append="clickAppendHandler"
    @click:append-outer="clickAppendOuterHandler"
    @click:clear="clickClearHandler"
    @focus="onFocus"
    @keydown.native="onKeyDown"
  >

    <template slot="label" v-if="label">
      {{ label }} <span class="mandatory accent--text" v-if="required">*</span>
    </template>

    <template slot="append-outer" v-if="$slots['append-outer']">
      <slot name="append-outer"></slot>
    </template>
    <template slot="append">
      <v-icon small v-if="translatable" class="translatable-icon">translate</v-icon>
    </template>
  </v-text-field>
</template>


<script>

import {length} from '@/scripts/helpers/StringHelpers';
import {isNull} from '@/scripts/helpers/ObjectHelpers';

export default {
  name: 'FormField',
  props: {
    autofocus: {
      type: Boolean,
      default: false
    },
    appendOuterIcon: {
      type: String
    },
    clearable: {
      type: Boolean,
      default: false
    },
    counter: {
      type: [Boolean, Number, String],
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hideable: {
      type: Boolean,
      default: false
    },
    hint: {
      type: String,
    },
    label: {
      type: String,
    },
    maxlength: {
      type: [Number, String]
    },
    name: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    prefix: {
      type: String,
      default: undefined
    },
    readonly: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default() {
        return [];
      }
    },
    selectOnFocus: {
      type: Boolean,
      default: false
    },
    suffix: {
      type: String,
      default: undefined
    },
    type: {
      type: String,
      default: 'text'
    },
    value: {
      type: [String, Number],
    },
    translatable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isVisible: true
    }
  },
  mounted() {
    this.isVisible = (this.hideable !== true);
  },
  computed: {
    computedValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        // Emito un evento Input y desde fuera del componente se recoge como si fuese un campo input nativo
        if (this.isLikeNumber) {
          newValue = parseFloat(String(newValue).split(',').join('.'));
          newValue = isNaN(newValue) ? null : newValue;
        }
        //console.log('newValue: ', newValue);
        this.$emit('input', newValue);
      }
    },
    isLikeNumber() {
      return (this.type === 'number' || this.type === 'currency');
    },
    persistentHint() {
      return !!(this.hint);
    },
    internalType() {
      if (this.isLikeNumber) {
        // Debido a un bug en Chrome () tengo que tratar los Number como Text para que no pierdan el valor al poner comas
        return 'text';
        //return 'number';
      } else if (this.hideable) {
        return (!this.isVisible) ? 'password' : 'text';
      }
      return this.type;
    },
    internalClearable() {
      // Si esta deshabilitado o en modo solo lectura no lo hago clearable
      return (!this.disabled && !this.readonly) ? this.clearable : false;
    },
    internalCounter() {
      // Solo muestro el counter si hay algo escrito
      return (!isNull(this.counter) && length(this.computedValue) > 0) ? this.counter : false;
    },
    appendIcon() {
      if (this.hideable) {
        return (this.isVisible) ? 'visibility' : 'visibility_off';
      }
      return undefined;
    },
    clickAppendHandler() {
      if (this.hideable) {
        return this.toggleVisibility;
      }
      return () => {
      };
    }
  },
  methods: {
    toggleVisibility() {
      this.isVisible = !this.isVisible;
    },
    clickClearHandler() {
      this.$emit('click:clear');
    },
    onFocus(e) {
      // Puedo seleccionar el contenido del campo en el onFocus
      if (this.selectOnFocus) {
        e.target.select();
      }
    },
    clickAppendOuterHandler() {
      this.$emit('click:append-outer');
    },
    onKeyDown(e) {
      if (this.isLikeNumber) {
        // Si no es un caracter valido para escribir un float detento la pulsacion
        if (!this.isFloatCharacter(e)) {
          e.preventDefault();
        }
      }
    },
    // Caracteres validos para escribir un float (numeros, coma, punto y signo menos)
    isFloatCharacter(e) {
      var key = (e.which) ? e.which : e.keyCode;
      // eslint-disable-next-line
      var re = /[-\.\,\d]/;
      var validate = re.test(String(e.key).toLowerCase());
      // Caracter permitido o Sup(46), Del(8), flechas(37, 38, 39, 40), Enter(13)...
      return validate || key === 8 || key === 46 || key === 37 || key === 38 || key === 39 || key === 40 || key === 13;
    }
  }
}
</script>


<style lang="scss">
.formField {
  &--textAlignRight {
    input {
      text-align: right;
    }
  }

  .v-messages__message {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &.translatable {
    .v-text-field__slot {
      order: 1;
    }

    .v-input__append-inner {
      order: 3;

      .v-input__icon-- {
        width: auto;
        height: auto;
        min-width: unset;
      }
    }

    .v-input__append-inner + .v-input__append-inner {
      order: 2;
    }
  }

  .translatable-icon {
    color: steelblue;
  }
}
</style>

