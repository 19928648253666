import Vue from 'vue';
import {isString, objectToUrlParams} from '@/scripts/helpers/StringHelpers';
import {join, isNull, getProperty} from '@/scripts/helpers/ObjectHelpers';
import * as Errors from '@/scripts/Errors';

export function animateScrollTo(xPos = 0, yPos = 0, target = window) {
  target.scrollTo({top: yPos, left: xPos, behavior: 'smooth'});
}


export function animateScrollIntoView(target, offsetY = -150, options = {behavior: 'smooth'}) {
  if (target) {
    window.scrollTo({
      top: offset(target).top + offsetY,
      behavior: 'smooth'
    });
  }
}

// Busca un mensaje de error en el scope proporcionado y si lo encuentra mueve el scroll hasta el componente
export function animateScrollIntoError(scope, offsetY) {
  if (scope) {
    Vue.nextTick(() => {
      const firstInvalidField = getProperty(scope, '$el.querySelector(".error--text, .error")', null);
      if (firstInvalidField) {
        animateScrollIntoView(firstInvalidField, offsetY);
      }
    });
  }
}

// Devuelve un objeto con el top y left de un elemento con respecto al 0,0 del documento, no del viewport
export function offset(el) {
  const rect = el.getBoundingClientRect();
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return {top: rect.top + scrollTop, left: rect.left + scrollLeft}
}

// Carga un archivo externo
/*{
	path,
	config,
	raw = devuelve el archivo sin parsear a text
}*/
export function ajax(path, config = {}, raw = false) {
  var promise = new Promise(function (resolve, reject) {
    // Convierto las variables en un string de url
    if (!isNull(config.body)) {
      config.body = (isString(config.body)) ? config.body : objectToUrlParams(config.body);
      config.headers = new Headers({'Content-Type': 'application/x-www-form-urlencoded'})
    }

    let conf = join({
      method: 'GET',
      headers: new Headers(),
      mode: 'cors',
      cache: 'default'
    }, config);
    //console.log(' conf: ',  conf);

    fetch(path, conf)
      .then((response) => {
        //console.log('response: ', response.text());
        if (response.status === 200) {
          return Promise.resolve(response);
        } else {
          return Promise.reject(Errors.NO_RESULTS);
        }
      })
      .then((result) => {
        if (raw) {
          resolve(result);
        } else {
          resolve(result.text());
        }
      })
      .catch(reject)
  });
  return promise;
}

export function mailTo(to) {
  window.open('mailto:' + to, '_blank');
}

// Copia un string al portapapeles
// Si recibe un elemento del DOM trata de obtener su innerText
export function copyToClipboard(element) {
  if (!element) return Promise.reject(Errors.INVALID_VALUE);
  const textToCopy = (typeof element === 'string') ? element : element.innerText;

  const myTemporaryInputElement = document.createElement('input');
  myTemporaryInputElement.type = 'text';
  myTemporaryInputElement.value = textToCopy;

  document.body.appendChild(myTemporaryInputElement);

  myTemporaryInputElement.select();
  document.execCommand('Copy');

  document.body.removeChild(myTemporaryInputElement);
  return Promise.resolve(textToCopy);
}

// Descarga directamente un contenido de texto como una archivo
export function downloadAsFile(content, fileName, contentType = 'text/plain') {
  var a = document.createElement('a');
  var file = new Blob([content], {type: contentType});
  a.href = URL.createObjectURL(file);
  a.download = fileName;
  a.click();
  return Promise.resolve(fileName);
}
